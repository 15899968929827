import React from "react";

import Layout from "../../../components/base/layout";
import SEO from "../../../components/base/seo";

const CRMSetupIntegration = () => (
  <Layout>
    <SEO title="Visual Language Package Addons" />
    <div className="container">
      <section className="section-checkout-form page-heading">
        <div className="checkout-product-heading">
          <h2>CRM Setup & Integration | $850 CAD</h2>
        </div>
        <div className="checkout-form-details">
          <div className="checkout-desc">
            <h5 className="heading">INCLUDED:</h5>
            <ul>
              <li>Discovery meeting with our inbound specialist</li>
              <li>Tech recommendation</li>
              <li>Basic CRM setup</li>
              <li>CRM integration with website</li>
              <li>Testing & debugging</li>
            </ul>

            <p className="text-small">
              <i>
                Note: This package does not include the cost of the CRM or paid
                CRM integration plugins. We do not charge a markup on those and
                they will need to be purchased directly by the client, using
                their preferred payment method.
              </i>
            </p>
            <p className="text-red">
              This package can only be purchased as an add-on to one of our
              other services. Please return to the services page and select this
              add-on during the checkout process.
            </p>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default CRMSetupIntegration;
